<script setup lang="ts">
import { initFlowbite } from 'flowbite';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { emitter } from '~/events';

import MenuItem from './MenuItem.vue';

const route = useRoute()
const appStore = useAppStore()
const breakpoints = useBreakpoints(appStore.breakpoints)
const whatsapp = ref<string>('553135001363');
const visible = ref(false);
const sidebarPt = ref<any>({})

const laptop = breakpoints.xl

emitter.on('toggle-sidebar', (_) => {
  visible.value = !visible.value
})

const setSitebarPt = (): any => {
  return sidebarPt.value = {
    content: {
      class: [
        // Spacing and Size
        'fixed',
        'xl:relative',
        'xl:float-left',
        'w-64',
        'h-screen',
        'min-h-screen',
        'overflow-y-auto',
        'xl:overflow-x-hidden',
        'text-sm',
        'bg-secondary-940',
        'border-gray-700',
        'pt-5',
        'xl:mt-10',

        // Growth and Overflow
        'grow'
      ]
    },
    mask: {
      class: [
        // Transitions
        'transition',
        'duration-200',
        { 'w-64': laptop.value },
        { '!z-10': laptop.value },

        // Background and Effects
        { 'bg-black/40': !laptop.value, 'backdrop-blur-sm': !laptop.value }
      ],
      style: {
        'width': !laptop.value ? '100%' : undefined,
        'position': laptop.value ? 'absolute' : 'fixed'
      },
    },
  }
}

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' || event.key === 'Esc') {
    event.preventDefault()
    visible.value = !visible.value
  }
}

watch(() => route.fullPath, (_) => !laptop.value ? visible.value = false : null)

watch(laptop, (val) => {
  visible.value = val
  setSitebarPt()
})

onMounted(() => {
  initFlowbite()
  window.addEventListener('keydown', handleKeydown)
  visible.value = laptop.value
  setSitebarPt()
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})
</script>
<template>
  <Sidebar v-model:visible="visible" :block-scroll="!laptop" :show-close-icon="false" :modal="!laptop"
    :dismissable="!laptop" class="!w-64" :pt="sidebarPt" :update:modelValue="visible">
    <div class="left-sidebar">
      <div class="xl:h-full px-3 pb-4 overflow-y-auto xl:overflow-hidden">
        <div class="py-2 px-5 cursor-default select-none sm:hidden">
          <div class="w-full pb-4 flex justify-between items-center relative">
            <nuxt-link :to="{ name: 'sports' }"
              :class="{ 'link-active': $route.name == 'sports' }">
              <span class="font-bold white-space-nowrap uppercase">Apostas Esportivas</span>
            </nuxt-link>
          </div>
        </div>
        <Accordion :activeIndex="0" class="relative xl:pt-0">
          <template #collapseicon>
            <Icon name="gravity-ui:chevron-up" />
          </template>
          <template #expandicon>
            <Icon name="gravity-ui:chevron-down" />
          </template>
          <AccordionTab>
            <template #header>
              <span class="flex items-center gap-2 w-full">
                <span class="font-bold white-space-nowrap uppercase">Cassino</span>
              </span>
            </template>

            <ul class="pt-2 space-y-1 font-medium">
              <li>
                <nuxt-link :to="{ name: 'index' }" class="one-menu" :class="{ 'link-active': $route.name == 'index' }">
                  <MenuItem title="Cassino" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'slots' } }" class="one-menu"
                  :class="{ 'link-active': $route.name == 'casino-lobby-slug' && $route.params.slug == 'slots' }">
                  <MenuItem title="Jogos Slots" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'crashgames' } }" class="one-menu"
                  :class="{ 'link-active': $route.name == 'casino-lobby-slug' && $route.params.slug == 'crashgames' }">
                  <MenuItem title="Jogos Crash" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'cassino-ao-vivo' } }" class="one-menu"
                  :class="{ 'link-active': $route.name == 'casino-lobby-slug' && $route.params.slug == 'cassino-ao-vivo' }">
                  <MenuItem title="Cassino Ao Vivo" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'casino-provider' }" class="one-menu"
                  :class="{ 'link-active': $route.name == 'casino-provider' }">
                  <MenuItem title="Provedores" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'mines' } }" class="one-menu"
                  :class="{ 'link-active': $route.name == 'casino-lobby-slug' && $route.params.slug == 'mines' }">
                  <MenuItem title="Mines" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pgsoft', gameId: 'fortune-tiger' } }"
                  class="one-menu">
                  <MenuItem title="Fortune Tiger" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="{ name: 'casino-providerId-gameId', params: { providerId: 'spribe', gameId: 'aviator' } }"
                  class="one-menu">
                  <MenuItem title="Aviator" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play', gameId: 'gates-of-olympus' } }"
                  class="one-menu">
                  <MenuItem title="Gates of Olympus" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play-live', gameId: 'spaceman' } }"
                  class="one-menu">
                  <MenuItem title="Spaceman" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link disabled class="one-menu cursor-pointer">
                  <MenuItem title="Infinite Blackjack" />
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play-live', gameId: 'roulette-green' } }"
                  class="one-menu">
                  <MenuItem title="Roleta Brasileira" />
                </nuxt-link>
              </li>
            </ul>
          </AccordionTab>
        </Accordion>
        <ul class="relative pt-4 mt-4 space-y-1 font-medium before-gradient">
          <li>
            <nuxt-link :to="{ name: 'promotions' }" class="one-menu"
              :class="{ 'link-active': $route.name == 'promotions' }">
              <MenuItem title="Bônus e Promoções" />
            </nuxt-link>
          </li>
          <li>
            <nuxt-link :to="{ name: 'account-refers' }" class="one-menu">
              <MenuItem title="Indicação de Amigos" />
            </nuxt-link>
          </li>
          <li>
            <nuxt-link :to="{ name: 'account-affiliate' }" class="one-menu"
              :class="{ 'link-active': $route.name == 'account-affiliate' }">
              <MenuItem title="Afiliados" />
            </nuxt-link>
          </li>
          <li>
            <nuxt-link :to="{ name: 'promotions-promotion', params: { promotion: 'cashback-semanal' } }"
              class="one-menu"
              :class="{ 'link-active': $route.name == 'promotions-promotion' && $route.params.promotion === 'cashback-semanal' }">
              <MenuItem title="Cashback" />
            </nuxt-link>
          </li>
        </ul>
        <ul class="relative grid gap-2 py-4 my-4 space-y-1 font-medium before-gradient xl:w-11/12 text-sm">
          <li class="w-full flex justify-center items-center">
            <a :href="`https://api.whatsapp.com/send?phone=${whatsapp}&text=Ol%C3%A1%2C%20MiPix%21`" class="hover:opacity-80" >
              <img src="~/assets/images/support.png" alt="Support" class="w-48" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Sidebar>
</template>

<style scoped>
a.one-menu {
  @apply text-sm font-semibold flex items-center p-2 rounded-lg text-white transition-colors ease-in-out delay-75;
  /* transition: color .1s ease-in-out; */
}

a.one-menu:hover {
  @apply hover:text-primary-300
}

a.one-menu.link-active {
  @apply text-primary-300
}

a.one-menu::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #1fab7d;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  font-size: 1.5em;
  margin-top: -3px;
}
</style>