<template>
  <div>
    <AppHeader />
    <MipixSidebar />
    <div class="sm:mt-10 pb-8 xl:pb-0 pt-3 mt-8 xl:ml-64">
      <div class="flex items-center justify-center rounded mt-3 sm:px-3" v-if="$route.name === 'index'">
        <CassinoSlider />
      </div>
      <div class="min-h-screen px-3">
        <slot />
      </div>
    </div>
    <AppFooter />
  </div>
</template>
