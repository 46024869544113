<script setup lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore from 'swiper/core'

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
SwiperCore.use([Autoplay, Navigation, Pagination]);

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.css'

import Banner1 from '~/assets/banners/new/BANNER1.png'
import Banner1Mobile from '~/assets/banners/new/BANNER1M.png'

import Banner2 from '~/assets/banners/new/BANNER2.png'
import Banner2Mobile from '~/assets/banners/new/BANNER2M.png'
import Banner3 from '~/assets/banners/new/BANNER3.png'
import Banner3Mobile from '~/assets/banners/new/BANNER3M.png'

const appStore = useAppStore()
const paginationRender = (_: SwiperCore, paginationEl: HTMLElement) => {
  const bullet = Array.prototype.slice.call(paginationEl.getElementsByTagName('span'))
  bullet.forEach((elm: HTMLSpanElement) => elm.classList.add('swiper-pagination-new'))
}

const laptop = ref<boolean>(true)
const breakpoints = useBreakpoints(appStore.breakpoints)

const onSlideChange = () => playAllVideos()

const playAllVideos = () => {
  const videos = document.querySelectorAll('.swiper-wrapper video');
  videos.forEach(async (video) => {
    try {
      let v = video as HTMLMediaElement;
      var isPlaying = v.currentTime > 0 && !v.paused && !v.ended && v.readyState > v.HAVE_CURRENT_DATA;

      if(!isPlaying)
        await v.play();
    } catch (error) {
      console.error('Error playing video:', error);
    }
  });
}

onMounted(() => laptop.value = breakpoints.lg.value)

watch(() => breakpoints.lg.value, (val) => {
  laptop.value = val
})
</script>
<template>
  <swiper class="feature-cards xl:max-h-[32rem]" :slidesPerView="1" :autoplay="{ delay: 4_000 }" :pagination="{ clickable: true }" :onPaginationRender="paginationRender" :onSlideChange="onSlideChange">
    <swiper-slide class="pb-8" v-if="laptop">
      <nuxt-link :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pgsoft', gameId: 'fortune-tiger' } }">
        <video :controls="false" autoplay muted playsinline loop class="w-full">
          <source src="https://cdn.mipix.bet/uploads/videos/tiger-d_hUFnDJ4b.mp4" type="video/mp4">
          <img :src="Banner3" class="min-w-full rounded-lg" />
        </video>
      </nuxt-link>
    </swiper-slide>
    <swiper-slide class="pb-6" v-else>
      <nuxt-link :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pgsoft', gameId: 'fortune-tiger' } }">
        <video :controls="false" autoplay muted playsinline loop class="w-full">
          <source src="https://cdn.mipix.bet/uploads/videos/tiger-m_hUFnDJ4b.mp4" type="video/mp4">
          <img :src="Banner3Mobile" class="min-w-full rounded-lg" />
        </video>
      </nuxt-link>
    </swiper-slide>
    <swiper-slide class="pb-8" v-if="laptop">
      <nuxt-link :to="{ name: 'casino-providerId-gameId', params: { providerId: 'spribe', gameId: 'aviator' } }">
        <video :controls="false" autoplay muted playsinline loop class="w-full">
          <source src="https://cdn.mipix.bet/uploads/videos/aviao-d_UfRQ89xG.mp4" type="video/mp4">
          <img :src="Banner2" class="min-w-full rounded-lg" />
        </video>
      </nuxt-link>
    </swiper-slide>
    <swiper-slide class="pb-6" v-else>
      <nuxt-link :to="{ name: 'casino-providerId-gameId', params: { providerId: 'spribe', gameId: 'aviator' } }">
        <video :controls="false" autoplay muted playsinline loop class="w-full">
          <source src="https://cdn.mipix.bet/uploads/videos/aviao-m22UfRQ89xG.mp4" type="video/mp4">
          <img :src="Banner2Mobile" class="min-w-full rounded-lg" />
        </video>
      </nuxt-link>
    </swiper-slide>
    <swiper-slide class="pb-8" v-if="laptop">
      <nuxt-link :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play', gameId: 'gates-of-olympus' } }">
        <video :controls="false" autoplay muted playsinline loop class="w-full">
          <source src="https://cdn.mipix.bet/uploads/videos/veio_dsOgxsGSl.mp4" type="video/mp4">
          <img :src="Banner1" class="min-w-full rounded-lg" />
        </video>
      </nuxt-link>
    </swiper-slide>
    <swiper-slide class="pb-6" v-else>
      <nuxt-link :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play', gameId: 'gates-of-olympus' } }">
        <video :controls="false" autoplay muted playsinline loop class="w-full">
          <source src="https://cdn.mipix.bet/uploads/videos/veio_sOgxsGSl.mp4" type="video/mp4">
          <img :src="Banner1Mobile" class="min-w-full rounded-lg" />
        </video>
      </nuxt-link>
    </swiper-slide>
  </swiper>
</template>
